html {
    background-color: #ededed; 
}

.profil-pic {
    position: absolute;
    top: -88px;
    right: 10px;
}


.pic-image {
  position: absolute;
  width : 128px;
}


.project{
    width: 300px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh; 
    background-color: #ededed; 
  }
  
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .card {
    margin-bottom: 1em;
  }


/* css perso */

.main {
  display: flex;
}

.container {
  margin: 1em 2em 1em 10em;
}

.menu {
  z-index: 2;
  display: block;
  background-color: white;
  position: fixed;
  height: 100%;
  width: auto;

  & ul {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &_item {
    padding: 2em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-direction: column;
    align-items: center;

    &-icon {
      color: #919191;
      font-size: 2em;
    }

    &-image {
      height: 5em;
      border-radius: 50%;
    }

    &-text {
      color: #7a7a7a;
      font-size: 1.5em;
    }
  }
}

.post {
  margin-left: 10em;
  margin-bottom: 5em;

  &_featured-image {
    height: 400px; 
    width: 100%;
    object-fit: cover;
  }
}

.about {
  margin: auto;
  margin-top: 2em;
  width: 60%;
}

/*
Hello.js css
*/
.presentation {
  font-size: 3em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6em;
  justify-content: center;
  margin-top: 1em;

  &_name {
    font-size: 2em;
  }
}

.social-media {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &_item {
    background-color: white;
    border-radius: 5px;
    padding: 0.5em 1em;
    margin: 0.5em;
  }
}

.icon-tech {
  margin: 2em;
  display: flex;
  justify-content: center;
  font-size: 2em;

  &_item {
    margin: 0.3em;
  }
}

/*
NotFound.css
*/
.notFoundPage {
  display: block;
  text-align: center;

  & h1 {
    font-size: 4em;
    font-weight: bold;
  }

  & p {
    margin: 2em 0;
  }
}


@media screen and (max-width: 800px) {
  .menu {
    bottom: 0;
    height: auto;
    width: 100%;

    & ul {
      flex-direction: row;
    }

    &_item {
      padding: 0.8em;

      &-icon {
        font-size: 2em;
      }

      &-text {
        font-size: 1em;
      }

      &-image {
        height: 3em;
        border-radius: 50%;
      }
    }
  }


  .container {
    margin-left: 2em;
    margin-bottom: 10em;
  }

  .post {
    width: 90%;
    padding: 0 1em 0 1em;
    margin-left: 0em;
  }
  
  .about {
    width: 70%;
    padding: 1em;
    padding-bottom: 8em;
  }
}


@media screen and (max-width: 600px) {
  .post {
    width: 100%;
    font-size: 1em;
    padding: 1em;

    &_featured-image {
      height: 200px; 
    }
  }
  
  .about {
    width: 100%;
  } 

  .card-alex, .card-project {
    width: 100%;
  }
}